/** @format */

import { PANEL_TYPE } from 'config';

export const Panels = [
  { type: 'PHILIA', value: 'Philia', logo: '/logo/en_philia_logo.png' },
  { type: 'JING', value: 'جینگ', logo: '/logo/fa_philia_logo.png' },
];

const getPanelIntegration = () => {
  let panel = Panels.find((obj) => obj.type === PANEL_TYPE);
  return panel || Panels[0];
};

export default getPanelIntegration;
